import {
  Component,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { HomeService } from './home.service';
import { AppComponent } from 'src/app/app.component';
import { StaticMemory } from 'src/app/handler/static_memory';

export interface PhotosApi {
  title?: string;
  description?: string;
  url?: string;
  link?: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [HomeService],
})
export class HomeComponent implements AfterViewInit {
  corpUrl = environment.corpUrl;
  hostUrl = environment.hostUrl;
  data: any;
  value: any;
  errorMessage: string;
  loading = false;
  currentTabIndex = 2;
  totalRowLength: any;
  paginatorParam = null;
  paginationArray = [];
  pageSizeArr = [{ value: 5 }, { value: 10 }, { value: 20 }];
  selectedPageSize = 5;
  paginationIndex = 0;

  sliderArray: Array<PhotosApi> = [];
  limit: number = 10;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    center: true,
    dots: true,
    autoHeight: true,
    autoWidth: true,
    autoplaySpeed: 1000,
    navText: ['<', '>'],
    nav: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  menuHidden: boolean = false;

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private service: HomeService,
  ) {
    this.menuHidden = StaticMemory.menuHidden;
  }

  ngOnInit(): void {
    this.getHomeSliderSection();
    this.getEvents();
    this.getNewArticles();
  }

  navigateToShop(catId: string): void {
    this.router.navigate(['/shop'], { queryParams: { catId: catId } });
  }

  onClickBrand(): void {
    window.location.href = this.hostUrl + '/store/story'
  }

  clickToCollection(): void {
    window.location.href = this.hostUrl + '/store/pages/collections'
  }

  clickToFoodCollection(): void {
    window.location.href = this.hostUrl + '/store/pages/foods-premium'
  }

  navigateToEventDetails(link: string): void {
    window.location.href = link;
  }

  navigateToArticlesDetails(link: string): void {
    window.location.href = link;
  }

  onSlideClick(link: any): void {
    window.location.href = link;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const hash = window.location.hash;
      if (hash) {
        const linkElement = document.querySelector(`${hash}Anchor`) as HTMLAnchorElement;
        if (linkElement) {
          linkElement.click();
        }
      }
    }, 100);
  }

  getEvents(): void {
    this.loading = true;
    if (this.paginatorParam == null) {
      this.paginatorParam = {
        limit: this.selectedPageSize,
        skip: 0,
      };
    } else if (this.paginationArray.length > 0) {
      this.paginatorParam = {
        limit: this.selectedPageSize,
        skip: this.paginationIndex * this.selectedPageSize,
      };
    }
    this.service.getEvents(this.paginatorParam).subscribe(
      (success) => {
        try {
          if (success.status) {
            if (success.count) {
              this.totalRowLength = success.count;
              this.initPagination(success.count);
            } else {
              this.initPagination(success.count);
              this.onErrorResetPaginator();
            }
            this.loadEventsData(success.result);
          } else {
            this.initPagination(success.count);
            this.onErrorResetPaginator();
            this.errorMessage = success.message.toUpperCase();
            this.data = [];
          }
          this.loading = false;
        } catch (e) {
          this.initPagination(success.count);
          this.onErrorResetPaginator();
          this.errorMessage = 'INVALID RESPONSE';
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.errorMessage = error.status + ' : HTTP ERROR IN GET EVENTS LIST';
      },
    );
  }

  getNewArticles(): void {
    this.loading = true;
    if (this.paginatorParam == null) {
      this.paginatorParam = {
        limit: this.selectedPageSize,
        skip: 0,
      };
    } else if (this.paginationArray.length > 0) {
      this.paginatorParam = {
        limit: this.selectedPageSize,
        skip: this.paginationIndex * this.selectedPageSize,
      };
    }
    this.service.getNewArticles(this.paginatorParam).subscribe(
      (success) => {
        try {
          if (success.status) {
            if (success.count !== undefined && success.count !== null && success.count !== 0) {
              this.totalRowLength = success.count;
              this.initPagination(success.count);
            } else {
              this.initPagination(success.count);
              this.onErrorResetPaginator();
            }
            this.loadNewsArticlesData(success.result);
          } else {
            this.initPagination(success.count);
            this.onErrorResetPaginator();
            this.errorMessage = success.message.toUpperCase();
            this.value = [];
          }
          this.loading = false;
        } catch (e) {
          this.initPagination(success.count);
          this.onErrorResetPaginator();
          this.errorMessage = 'INVALID RESPONSE';
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.errorMessage = error.status + ' : HTTP ERROR IN GET NEWS ARTICLES LIST';
      },
    );
  }

  getHomeSliderSection(): void {
    this.loading = true;
    this.service.getHomeSliderSection({ groupName: 'HOMESLIDER' }).subscribe(
      (success) => {
        console.log(success);

        try {
          if (success.status) {
            this.sliderArray = success.result[0].subSectionDetails;
          } else {
            this.errorMessage = success.message.toUpperCase();
          }
        } catch (e) {
          this.errorMessage = 'INVALID RESPONSE';
          this.loading = false;
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        this.errorMessage = error.status + ' : HTTP ERROR IN GET SLIDER';
      },
    );
  }

  loadEventsData(data: any): void {
    try {
      if (data && data.length) {
        for (let i = 0; i < data.length; i++) {
          data[i].eventName_text = '';
          data[i].description_text = '';
          data[i].imglink = '';
          if (data[i].eventName && data[i].eventName.length) {
            data[i].eventName_text = data[i].eventName;
          }
          if (data[i].description && data[i].description.length) {
            data[i].description_text = data[i].description;
          }
          if (data[i].images && data[i].images.length) {
            data[i].imglink = data[i].images[0].link;
          }
          data[i].knowMoreLink = environment.hostUrl + '/store/event-detail/' + data[i].id;
        }
        if (data.length > 3) data = data.splice(0, 3);
        this.data = data;
      } else {
        this.data = [];
      }
    } catch (e) {
      this.errorMessage = 'DATA LOAD ERROR';
    }
  }

  loadNewsArticlesData(value: any): void {
    try {
      if (value !== undefined && value !== null && value.length !== 0) {
        for (let i = 0; i < value.length; i++) {
          if (value[i].title === undefined || value[i].title === null || value[i].title === '') {
            value[i].title_text = '';
          } else {
            value[i].title_text = value[i].title;
          }
          if (
            value[i].description === undefined ||
            value[i].description === null ||
            value[i].description === ''
          ) {
            value[i].description_text = '';
          } else {
            value[i].description_text = value[i].description;
          }
          if (
            value[i].image === undefined ||
            value[i].image === null ||
            value[i].image.length === 0
          ) {
            value[i].image_text = '';
          } else {
            value[i].image_text = value[i].image[0].link;
          }
          value[i].articleLink = environment.hostUrl + '/store/news-articles/';
        }
        if (value.length > 3) value = value.splice(0, 3);
        this.value = value;
      } else {
        this.value = [];
      }
    } catch (e) {
      this.errorMessage = 'DATA LOAD ERROR';
    }
  }

  initPagination(totalRow: any): void {
    const pageCountD = totalRow / this.selectedPageSize;
    let pageCount = Math.floor(totalRow / this.selectedPageSize);
    if (pageCountD - pageCount > 0) {
      pageCount++;
    }
    this.paginationArray = [];
    for (let j = 0; j < pageCount; j++) {
      const idx = {
        pageStart: j * this.selectedPageSize + 1,
        pageEnd: (j + 1) * this.selectedPageSize,
        skip: j * this.selectedPageSize,
        limit: this.selectedPageSize,
      };

      if (idx.pageEnd > totalRow) {
        idx.pageEnd = totalRow;
      }
      this.paginationArray.push(idx);
    }
  }

  onFunctions(): void {
    this.getEvents();
  }

  onFirstPage(): void {
    this.paginationIndex = 0;
    this.onFunctions();
  }

  onPreviousPage(): void {
    this.paginationIndex--;
    this.onFunctions();
  }

  onNextPage(): void {
    this.paginationIndex++;
    this.onFunctions();
  }

  onLastPage(): void {
    this.paginationIndex = this.paginationArray.length - 1;
    this.onFunctions();
  }

  onPageSizeChange(event: any): void {
    this.paginatorParam = null;
    this.paginationIndex = 0;
    this.onFunctions();
  }

  onResetPaginator(): void {
    this.paginationIndex = 0;
    this.paginatorParam = null;
  }

  onResetPaginatorAndSearch(): void {
    // this.searchText = '';
    this.paginationIndex = 0;
    this.paginatorParam = null;
  }

  onErrorResetPaginator(): void {
    this.totalRowLength = 0;
    this.paginationArray.push({
      pageStart: 0,
      pageEnd: 0,
    });
  }
}
