export class StaticMemory {
  public static isAuthVerified = false;
  public static xRoute = '&T(%^&IYU6756856iyku,gKYUGL>IUH' + window.location.hostname;
  public static showHomeNavigation = false;
  public static entityName = '';
  public static logo = '';
  public static email = '';
  public static walletBalance = 0;
  public static cod = '';
  public static entityData: any;
  public static menuHidden = false;
}
