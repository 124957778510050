import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// xOriginKey for *www.themeghalayanage.store
if (
  environment.proxyApiUrl.includes('themeghalayanage.store') ||
  environment.proxyApiUrl.includes('themeghalayanage.com')
) {
  environment.xOrigin.key =
    'gAAAAABkR4aSN5ysAMXQnooU3r8Qt4PfiJ9ERg0l7V0fR-vJWPrRSoJFFzSpNpV3QyNz35hpHu-msM9tCVFR4TltL5c1Kr19rz02M99eRfHsErPVoS60TQQ=';
  environment.products.serviceAreaId = '613906e20adcdaaf95d7eab0';
  environment.products.categoryId = '6139ece96ab9f69a9092697d';
  environment.products.warehouseId = '638f425e6bcbb870f01580b8';
  environment.products.placeholder = 'assets/images/placeholder.png';
  environment.google.analyticsKey = 'G-SE6QXWZMFK';
  environment.guestHeaders.Authorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJnQUFBQUFCbGVhOVk2OW5lTTE3blh3bmxmdzF5ZVZvNjExMjRmZ0NMZWE5dHNZSE5sZWFGRFJXNUdqZVJQVmFoQWJDM0VMZTVydE9Jc3RkRTFJX3Vad3hwaDc2RVVzdERmbXJUM29BbmJYbHZnM0w1ZGxmdEV6ND0iLCJleHAiOjE3MzQwMDk1NjB9.g-NRM4Gjy2l2taYfN45cK72wmtYzoKUSBEehCKOx1lw';
  environment.guestHeaders['x-Api-Key'] =
    'gAAAAABlea9Ycg0cpah5EaA1S8yQ8K5A6X2hYUbT8b5pmHxBUIh492W7JTNuLZjM2sSkL2Gwd35oWrc-rPdto50-4Z5TEQXnhDYZE0LgVvbmIYiK-1xjs0A=';
  environment.guestHeaders['x-Origin-Key'] =
    'gAAAAABlea9YUBFbFxiCLJ-z74Zj3_H-8ILY_0ADUthoIIfGOFRkA6rAwsL94Mv4LnfMnoFJuWgIyBLZdehy1bebBrC5qdhYQMgDJbPYumc1J3h7NnmQg-I=';

  if (environment.hostUrl.includes('.com')) {
    environment.showCorpLogo = true;
  }
  environment.corpUrl = environment.hostUrl.replace('/www', '/corp').replace('.store', '.com');
}

// xOriginKey for *www.Jompoi.com
if (
  environment.proxyApiUrl.includes('jompoi.com')
) {
  environment.xOrigin.key =
    'gAAAAABkR7dBgI-YE1Bg8V3DSBSCr9jsNxQdcAfl2rw4661c7Gqv0OV_7u1UPyqEH_JkcKD1FPfGpIZmoR9y8yGTDC0MqZmsEF2thpqOzfj5qE1V0yPRgVc=';
  environment.products.warehouseId = '60c369fbda3527256519bfb2';
  environment.products.serviceAreaId = '60c27372a714eadc5f94aa54';
  environment.products.categoryId = '64968da07321f2e250577f73';
  environment.products.placeholder = 'assets/images/placeholder.png';
  environment.guestHeaders.Authorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJnQUFBQUFCa3lsbG14WERpOHdKakNTSWtlM0lVVkZHM183UEtvUUJqb0Z6NFVGZGcxQTE1Y0RQQm9lMUsxdWdQSlJmd1NLZHYxUWFzSVYxdkI1aEc0ODl5NktmeHJoWFZ0VWxvanI2R2xwWlBkYlEtS2dHOFhLMD0iLCJleHAiOjE3MjI1MTg3NTh9.D4SGMMpp0xSwaza2SvvS3JDj_hbhYXSdQDj8LLYgloE';
  environment.guestHeaders['x-Api-Key'] =
    'gAAAAABkyllmAaUDqtMBnsf7CPlsiPBNFOAbzANtFApTdkZPH5TNBw-hjMum9YIz5R_Nst0PFMJQ6BVuAvK8-1PX0h4I6Zr4qb7YRGsnXPELN2sw5pLMPXY=';
  environment.guestHeaders['x-Origin-Key'] =
    'gAAAAABkyllmE2R3m2DkU5ZlnlLEFBry2aMYHcHUDevyN3HSFfEbFbMio-2mIhgNri_SZKy9N30Utfh79-0Gm8oeEIlRqm_rU135ixGgH4hhXK91kFWIeWs=';
}

// xOriginKey for *https://jplay.xlayer.in
if (
  environment.proxyApiUrl.includes('jplay.xlayer.in')
) {
  environment.xOrigin.key =
    'gAAAAABm_kTWQ_2brYv0xxWRr7bFIIm9W05HKwRyMRBcv5u2ayozABP7AddZ5Fdx6tSg85BQFfcF7cyVmzpFF00rpazsy2R7BXpW0yEN6ttUZeLMH4CuH9E=';
  environment.products.warehouseId = '65043a26c0cb3c8fc022b518';
  environment.products.serviceAreaId = '65638dfa6ebc3e4ed0729f74';
  environment.products.categoryId = '64968da07321f2e250577f73';
  environment.products.placeholder = 'assets/images/placeholder.png';
  environment.guestHeaders.Authorization =
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJnQUFBQUFCbV9fYXl2UjAwaUFTWTRXNV91NVpGcVJhQnFRYWR1aldFeWFYZkxCX05MRkpmMURfRFpXcjRvcDZrX2l6RjJmLTVua3lVdTh1d2xhcEYtUlY0ZFF6NmRqVi1OcDlrSFR4dXN0Ym8xbUg1Qi0zVVQ3dz0iLCJleHAiOjE3NTk1ODY4NjZ9.Fdps2cA69yJ9QWwEHiXQXK6j9dOTwxWLLcBrC9ZvGn0';
  environment.guestHeaders['x-Api-Key'] =
    'gAAAAABm__ayHLe3q7e3y4hwNmsKcAbAjFGeD7oo1Lqy2baKYhAREnRTzIz-Lmt3DXlT46BaeHeeytzG4rxC6KQl_5Q3tApFrc2pbZEBwB7aIbmDlZDiq40=';
  environment.guestHeaders['x-Origin-Key'] =
    'gAAAAABm__ayYzQvwoinh8RuaMCs7E5Lo-OxjjvLCvl3dbmbRS2dhXg16OGyl962069T7cMJt6IIJjQDCqHlVfLOiDrRcfSttI-6dy-tAB_16YNh-cxXg9o=';
}

if (environment.proxyApiUrl.includes('demo-xcom.xlayer.in')) {
  environment.xOrigin.key = 'gAAAAABlIPvbW43Wt4tuhIZGsMaRsY9uMxY4nx7FNuBgTNj2LevyjfIJDxFaAKeqe20F0tlU6Ibdwwvp7_NrcqXirWm4r6dnv05YbgtFEE1lB-SidKZTgB0='
  environment.products.warehouseId = '65043a26c0cb3c8fc022b518';
  environment.products.serviceAreaId = '65044e20cb87a560deb7e88e';
}

environment.guestHeaders['x-Origin-Key'] = environment.xOrigin.key;

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
